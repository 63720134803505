import React, { useEffect, useState } from "react";
import { Globe } from "./globe/Globe";
import './Section2.css'

export const Section2 = ({contents2}) => {
    const arrCont = contents2.values_def

    const [Desc, setDesc] = useState({ tittle: arrCont[0].tittle, description: arrCont[0].text });
    useEffect(() => {
        document.getElementById("checkbox-0").checked = true;
    }, [])

    function unCheck(e, id) {

        const globes = ["checkbox-0", "checkbox-1", "checkbox-2", "checkbox-3", "checkbox-4", "checkbox-5", "checkbox-6", "checkbox-7"]
        let globes_count = 0;

        arrCont.forEach((arrCont) => {
            if (arrCont.id === id) {
                setDesc({ tittle: arrCont.tittle, description: arrCont.text })
            }
        })

        for (let i = 0; i < globes.length; i++) {
            if (document.getElementById(globes[i]).checked === false) {
                globes_count++
            }
            if (i !== id) {
                document.getElementById(globes[i]).checked = false;
            }
            if (globes_count === 7) {
                document.getElementById(globes[0]).checked = true;
                setDesc({ tittle: arrCont[0].tittle, description: arrCont[0].text })
            }
        }
    }

    return (
        <section className="o-values Section">
            <div className="o-targets">
                <Globe idCheck="checkbox-0" imgFront={arrCont[0].imgFront} imgBack={arrCont[0].imgBack} imgST={arrCont[0].imgST} eventCheck={(e) => unCheck(e, 0)} />
                <Globe idCheck="checkbox-1" text={arrCont[1].tittle} imgFront={arrCont[1].imgFront} imgBack={arrCont[1].imgBack} imgST={arrCont[1].imgST} eventCheck={(e) => unCheck(e, 1)} />
                <Globe idCheck="checkbox-2" text={arrCont[2].tittle} imgFront={arrCont[2].imgFront} imgBack={arrCont[2].imgBack} imgST={arrCont[2].imgST} eventCheck={(e) => unCheck(e, 2)} />
                <Globe idCheck="checkbox-3" text={arrCont[3].tittle} imgFront={arrCont[3].imgFront} imgBack={arrCont[3].imgBack} imgST={arrCont[3].imgST} eventCheck={(e) => unCheck(e, 3)} />
                <Globe idCheck="checkbox-4" text={arrCont[4].tittle} imgFront={arrCont[4].imgFront} imgBack={arrCont[4].imgBack} imgST={arrCont[4].imgST} eventCheck={(e) => unCheck(e, 4)} />
                <Globe idCheck="checkbox-5" text={arrCont[5].tittle} imgFront={arrCont[5].imgFront} imgBack={arrCont[5].imgBack} imgST={arrCont[5].imgST} eventCheck={(e) => unCheck(e, 5)} />
                <Globe idCheck="checkbox-6" text={arrCont[6].tittle} imgFront={arrCont[6].imgFront} imgBack={arrCont[6].imgBack} imgST={arrCont[6].imgST} eventCheck={(e) => unCheck(e, 6)} />
                <Globe idCheck="checkbox-7" text={arrCont[7].tittle} imgFront={arrCont[7].imgFront} imgBack={arrCont[7].imgBack} imgST={arrCont[7].imgST} eventCheck={(e) => unCheck(e, 7)} />
            </div>

            <div className="o-content">
                <h1 className="o-content-title">{Desc.tittle}</h1>
                <p className="o-content-desc">{Desc.description}</p>
                <p className="o-content-info">{contents2.call_acction.text1}<br></br>{contents2.call_acction.text2}</p>
            </div>
        </section>
    )
}