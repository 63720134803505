import React from "react";
import './Dots.css'

export const Dots = ({ items, active, onClick }) => {
    return (
        <ul className={'dotList'}>

        {Array.from(Array(items).keys()).map((dot) => (
            <><li
                key={dot}
                className={ `dot ${active === dot? '': 'active'}`}
                onClick={() => onClick(dot)}
            />
            </>
        ))}
        </ul>
    );
}

 