import React from "react";
import './Section1.css';



export const Section1 = ({contents1}) => {
    return (
        <section className=" o-service Section">
            <div className="o-content">
                <h1 className="o-content-title">{contents1}</h1>
            </div>
        </section>
    )
}