import React, { useEffect, useState } from "react";
import { NavLink } from '../NavLink/NavLink';
import './Menu.css'
import './Menu-movil.css'



export const Menu= ({isHeader, isMovil, enable}) =>{
    
    const [enable_, setEnable]= useState(enable)
    const [enableDropdown, setEnableDropdown]= useState(false)

    useEffect(() => {
        setEnable(enable)
        if(enable) setEnableDropdown(false)
    }, [enable]);


    const enableDro = () =>{
        setEnableDropdown(!enableDropdown)
    }
    return(
        <nav className={`menu ${isMovil? 'isMovil': ''} ${enable_? '': 'disable'}`}>
            <ul className={`${isHeader? 'isHeader': 'noHeader'}`}>
                <li  ><NavLink to='/home'>Inicio</NavLink></li>
                <li  ><NavLink to='/services'>Servicios</NavLink> </li>

                <li className="dropdown" onClick={isMovil? enableDro : ()=>{}}>
                    { isMovil? '' : 
                        <ul className="internal-nav">
                            <li><NavLink to='/about-us-company'>Empresa</NavLink></li>
                            <li><NavLink to='/KnowUs'>Comunidad</NavLink></li>
                        </ul>
                    }
                <span>Conócenos</span>                 
                </li>
                <li className={enableDropdown? 'drpdwnLi': 'disable'}><NavLink to='/about-us-company'>Empresa</NavLink></li>
                <li className={enableDropdown? 'drpdwnLi': 'disable'}><NavLink to='/KnowUs'>Comunidad</NavLink></li>
            </ul>
        </nav>
    )
}