import React, { useEffect } from "react";
import { Banner } from "../Components/HomePage/Banner/Banner";

import { Section1 } from "../Components/HomePage/Section1/Section1";
import { Section2 } from "../Components/HomePage/Section2/Section2";
import { Section3 } from "../Components/HomePage/Section3/Section3";
import { Section4 } from "../Components/HomePage/Section4/Section4";


export const MainPage = ({content}) =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <Banner/>
            <Section1 contentS1={content.news} isEng={false}/>
            <Section2 contentS2={content.work_with_us} isEng={false}/>
            <Section3 contentS3={content.do} isEng={false}/>
            <Section4 contentS4={content.wadua_culture} isEng={false}/>
        </>
    )
}