import React, { useEffect, useState } from "react";
import './OurValues.css'
import './OurValues-Movil.css'
import { useWindowSize } from "../../hooks/windowSize";

export const OurValues = (prop) =>{

    const content = prop.content;

    const title = content.title
    const descr = content.desc

    //* Hooks
    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version
    const [enableModal, setEnableModal] = useState(false);  

    const [desc, setDesc] = useState('');
    const [title2, setTitle2] = useState('');


    //* read windows width size and  set hooks true or false
    const screenWidth = useWindowSize();    

    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 1024 ) {
            setisVisible(false)
        } else if (screenWidth <= 1023) {
            setisVisible(true)
        }
    }, [screenWidth]);

    function showModule(e, desc, title) {
        setDesc(desc)
        setTitle2(title)
        setEnableModal(true)   
    }
    function hiddenModule() {
        setEnableModal(false)
    }

    return (
        <section className="our-values">
            <h2 className="ov-title general-title-h2">{title}</h2>
            <p className="ov-desc">{descr}</p>
            <div className="ov-content">
                {content.all_values.map((data) =>{
                    return(
                    <div className="ov-card" onClick={isVisible?(e)=>showModule(e, data.desc, data.title):()=>{}}>
                        <h3 className="ov-card-title">{data.title}</h3>
                        <p className="ov-card-p">{isVisible?data.alt_desc:data.desc}</p>
                    </div>
                    )
                })}
            </div>

            <div  className={`modalAUD modalOV ${enableModal? '': 'disable'}`} onClick={(e)=>hiddenModule()}>
                <div className="modalAUD-content">
                    <h2 className="modalAUD-title">{title2}</h2>
                    <p className="modalAUD-content-p">{desc}</p>
                    <button className="btn-modal" onClick={(e)=>hiddenModule()}>
                        X
                    </button>
                </div>
            </div>
        </section>
    )
}