import React from "react";
import { OurTeam } from "../../OurTeam/OurTeam";
import './Section3ABUC.css'
import './Section3ABUC-Movil.css'



export const Section3ABUC = (prop) =>{

    const contentS3 = prop.contentS3

    const title = contentS3.title
    const title2 = contentS3.title2
    const text = contentS3.desc
    
    return(
        <section className="Section3ABUC-desc Section">
            <div className="Section3ABUC-team">
                <h2 className="Section3ABUC-team-title general-title-h2">{title2}</h2>
                <div className="Section3ABUC-team-content">
                    <OurTeam/>
                </div>
            </div>
            <div className="Section3ABUC-content">
                <h2 className="Section3ABUC-content-title general-title-h2">{title}</h2>
                <p className="Section3ABUC-content-desc">{text}</p>
            </div>
        </section>
    )
}