import React from "react";
import "./Socialmedia.css";

export const Socialmedia = () => {
    return (
        <>
        <div className="socialButton">
            <a
            className="socialLogo"
            href="https://co.linkedin.com/company/bamboo-analytics-sas"
            target='_blank'
            rel=" noopener noreferrer"
            >
            <img src="/resources/Vectores/lkin2.svg" alt="SVG linkedin icon" />
            </a>
        </div>
        <div className="socialButton">
            <a className="socialLogo" href="https://www.tiktok.com/@bamboo_sas" 
            target='_blank'
            rel=" noopener noreferrer">
            <img src="/resources/Vectores/tiktok2.svg" alt="SVG tiktok icon" />
            </a>
        </div>
        <div className="socialButton">
            <a className="socialLogo" href="https://www.instagram.com/bamboo_sas/"
            target='_blank' rel=" noopener noreferrer">
            <img src="/resources/Vectores/instagram2.svg" alt="SVG instagram icon" />
            </a>
        </div>
        </>
    );
};
