import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Footer } from './Components/Footer/Footer';
import { Header } from './Components/Header/Header';
import { AboutusCompany } from './Pages/AboutusCompany';
import { KnowUs } from './Pages/KnowUs';
import { MainPage } from './Pages/MainPage';
import { Services } from './Pages/Services';


function App() {
  
  const content2 = require('./Temps/ContentEs.json');

  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path='/' element={<MainPage content={content2.MainPageContent}/>}/>
        <Route path='/home' element={<MainPage content={content2.MainPageContent}/>}/>
        <Route path='/about-us-company' element={<AboutusCompany content={content2.AboutUsCompany}/>}/>
        <Route path='/KnowUs' element={<KnowUs content={content2.AboutUsComunity}/>}/>
        <Route path='/services' element={<Services content={content2.Services}/>}/>
      </Routes>
      <Footer/>
    </div>

    
  );
}

export default App;
