import React, {useState} from "react";
import './OurTeam.css'
import './OurTeam-Movil.css';

import { Slider } from "../Carousel2/Carousel2.js";

export const OurTeam = () =>{

    const [members, setMembers] = useState([]);
    const [isVisible, setisVisible] = useState(false);

    const teamMembers = require('../../Temps/teamMemersC.json')

    function bringMembers (data){
            setMembers(data.members)
            setisVisible(true) 
    }

    function disableMembers (){
        setisVisible(false)
    }

    
    return(
        <>
            <Slider  members={teamMembers.teamMemersC} visibleItemsNumber={2} isObject={false} disable ={isVisible? 'disable': ''} cant= {1} gap='20px'>
                {(data) => (                          
                    <div className={`category-teamMembers ${isVisible? 'disable': ''}`} >
                        <button className= {`category-teamMembers-img ${data.title}`} onClick={()=>bringMembers(data)}> 
                            <img  className="img-category img-team" src={data.img} alt="icon-Category"/>
                        </button>
                        <h3 className="category-teamMembers-title title-team">{data.title}</h3>
                    </div>
                )}
            </Slider> 
            <button className={`carousel-close ${isVisible? '' : 'disable'}`} onClick= {disableMembers}>
                <img src="/resources/Vectores/arrow2.svg" alt="arrow"/>
            </button>
            <div className={`carousel-team ${isVisible? '' : 'disable'}`}>
                <Slider  members={members} visibleItemsNumber={2}>
                    {(members) => (
                        <div>
                            <img
                                id={members.id}
                                src={members.img}
                                alt={`${members.name} pictures`}
                                className="image img-team img-team-member"
                            />
                            <h3 className="category-teamMembers-title title-team">{members.name}</h3>
                        </div>
                    )}
                </Slider>                
            </div>
            

        </>
    )
}