import React, {useEffect, useState} from "react";
import './Header.css'
import './Header-Movil.css'
import { Menu } from "../Menu/Menu";
import { Socialmedia } from "../SocialMedia/Socialmedia";
import { useWindowSize } from "../hooks/windowSize";
import { NavLink } from "../NavLink/NavLink";


export const Header = () =>{

    //* Hooks
    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version
    const [enable, setEnable] = useState(false);         // *show the nav var (mobile version)

    //* read windows width size and  set hooks true or false
    const screenWidth = useWindowSize();    

    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 1008 ) {
            setisVisible(false)
            setEnable(true)
        } else if (screenWidth <= 1007) {
            setisVisible(true)
            setEnable(false)
        }
    }, [screenWidth]);

    //* enable and disable mobile menu
    const enableMenu = () =>{
        setEnable(!enable)
    }  

    return(
        <header id="header">
            <img className="WaduaColor"src="/resources/Vectores/Wadua.svg" alt="Logo" />
            <NavLink to='/home' classnl='homeLink'></NavLink>
            <div className={`header-menu ${isVisible? '': 'disable'}`} onClick={enableMenu}>
                <img src={enable?'/resources/Vectores/closeIcon.svg':`/resources/Vectores/menuIcon.svg`} alt=""/>
            </div>
            <Menu isHeader={true} isMovil={isVisible} enable ={enable}/>
            <div className={`SM-container ${isVisible? 'disable': ''}`}>
                <Socialmedia/>
            </div>
            <div className={`back-header-movil ${isVisible&&enable? '': 'disable'}`} onClick={enableMenu}></div>
            
        </header>
    )
}