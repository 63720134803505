import React from "react";
import { Slider } from "../../Carousel2/Carousel2";
import './OurServices.css'
import './OurServices-Movil.css'

export const OurServices = (prop) =>{

    const content = prop.content;

    const title =  content.title

    return (
        <section className="our-services">
            <h2 className="our-services-title general-title-h2">{title}</h2>
            <div className="os-carousel-container">
                <Slider members={content.all_services} gap='0px'>
                {(data) => (
                        <div className="card-Service">
                            <div className="image-carosuel-service">
                                <div className={`category-teamMembers category-scnd`} >
                                    <div className= {`category-teamMembers-img category-secnd ${data.title}`}> 
                                        <img  className="img-category img-team category-secnd" src={data.img} alt="icon-Category"/>
                                    </div>
                                </div>
                            </div>
                            <div className="content-carousel-service">
                                <h3 className="title-content-cs">{data.title}</h3>
                                <p className="content-content-cs">{data.desc}</p>
                                <span className="span-content-cs">{ data.desc22 === undefined ? '':  data.desc22}</span>
                            </div>
                        </div>
                        
                    )}
                </Slider>
            </div>
        </section>
    )
}