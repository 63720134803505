import React from "react";
import { Card } from "./Card/Card";
import { Card_base } from "./Card/Card_base";
import './Section2.css'

export const Section2 = ({isMovil,contents2}) => {
    
    const arrCont = contents2.cards

    return (
        <div className={`Section o-serv-s2 ${isMovil? 'disable': ''}`}>
            <Card_base tittle={contents2.card_base.title} text={contents2.card_base.text}/>
            <Card id={arrCont[0].id} tittle={arrCont[0].title} text={arrCont[0].text} text2={arrCont[0].text2} img={arrCont[0].img} color={arrCont[0].color}/>
            <Card id={arrCont[1].id} tittle={arrCont[1].title} text={arrCont[1].text} text2={arrCont[1].text2} img={arrCont[1].img} color={arrCont[1].color}/>
            <Card id={arrCont[2].id} tittle={arrCont[2].title} text={arrCont[2].text} text2={arrCont[2].text2} img={arrCont[2].img} color={arrCont[2].color}/>
        </div>
    )
}