import React from "react";
import './Section3.css'
import './Section3-Movil.css'

import { Slider } from "../../Carousel2/Carousel2";

// *components
import { Button } from "../../Button/Button";
import { NavLink } from "../../NavLink/NavLink";

const programsIcons =[
    {
        id: 1,
        img: '/resources/Vectores/Logo Qlik.svg'
    },
    {
        id: 2,
        img: '/resources/Vectores/Logo Power BI.svg'
    },
    {
        id: 3,
        img: '/resources/Vectores/Logo Phyton.svg'
    },
    {
        id: 4,
        img: '/resources/Vectores/spotfire.svg'
    }
]

export const Section3 = (prop) =>{    


    const contentS3 = prop.contentS3

    const title = contentS3.title
    const text = contentS3.desc
    const btnText = contentS3.btn
    const title2 = contentS3.title2

    return(
        <section id="Section3" className="Section" >
            <div className="S3-content"> 
                    <h2 className="general-title-h2">{title}</h2> 
                    <p>{text}</p>              
                <NavLink className="S3-Btn" to="/services" classnl = 'S3-Btn'>                    
                    <Button textBtn={btnText} bckColorClase='' onIcon={false}/>
                </NavLink>
            </div>
            <div className="S3-image"> 
                <h2 className="S3-carousel-title general-title-h2">{title2}</h2>
                <div className="carousel-container">
                    <Slider  members={programsIcons} cant={1}>
                        {(content) => (
                            <div className={`carousel-image-container`}>
                                <img src={content.img} alt={content.img} /> 
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </section>
    )
}