import React, {useEffect, useState} from "react";
import { useSwipeable } from "react-swipeable";
import './Carousel2.css'
import './CarouselCard/Carouselcard.css'
import './CarouselKnowUs/CarouselKnowUs.css'
import './CarouselKnowUs/CarouselKnowUs_installs.css'
import { Dots } from "./Dots/Dots";
import { useWindowSize } from "../hooks/windowSize";


export const Slider = ({ members,children, isObject = false, disable, cant =1, slider_type = false, gap = '20px'}) => {


    const [visibleItemsNumber, setVisibleItemsNumber] = useState(cant);

    const screenWidth = useWindowSize();  

    useEffect(() => {
        
        if (slider_type === true){
            setVisibleItemsNumber(1)
        }
        
        else if (screenWidth < 769 ) {
            setVisibleItemsNumber(cant)

        } else if (screenWidth < 1007) {
            setVisibleItemsNumber(cant+1)
        }
        else if (screenWidth < 1920){
            setVisibleItemsNumber(cant+2)
        }
        else {
            setVisibleItemsNumber(cant+3)
        }
    }, [screenWidth]);


    const [start, setStart] = useState(0);
    const isControlsVisible = members.length > visibleItemsNumber;

    const visibleItems = isControlsVisible
        ? members.concat(members.slice(0, visibleItemsNumber)).slice(start, start + visibleItemsNumber)
        : members;


    const handlers = useSwipeable({
        onSwipedLeft: () => setStart(start + 1 >= members.length ? 0 : start + 1),
        onSwipedRight: () => setStart(start - 1 >= 0 ? start - 1 : members.length - 1)
    });

    const styles= {
        'gap': gap
    }

    return (
        <div {...handlers} className={`carousel2 ${disable}`} >
            <div className={'slides'}>
            <ul className={'list'} style ={styles}>
                {isObject ? children ? children(1) : null :
                visibleItems.map((slide) =>
                    children ? children(slide) : null )
                }
            </ul>
            </div>
            {isControlsVisible && (
            <div className={'dotsControls'}>
                <Dots
                    items={members.length}
                    active={start}
                    onClick={(active) => setStart(active)}
                />
            </div>
            )}
        </div>
    );

}
