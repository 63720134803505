import React from "react";
import './Section4.css'
import { Slider } from "../../Carousel2/Carousel2";

export const Section4 = ({contents4}) => {

    const installs = contents4.locations
    const isMovil = true

    return (
        // <section className={`Section o-Section4`}>
         <section className={`Section o-Section4 ${isMovil? 'disable': ''}`}>
            <div className="o-Section-Cont">
                <div className="o-installs-tittle">
                    <h2 className="o-carousel-title">{contents4.title.text1}<br/>{contents4.title.text2}</h2>
                </div>
                
                <Slider members={installs} cant={1} slider_type = {true}>
                    {(content) => (
                        <div className={`o-carousel-tab`}>
                            <div className="o-carousel-cont">
                                <div className="o-carousel-install-tittle">
                                    <p>{content.name}</p>
                                </div>
                                <img className="o-img-installs" src={content.img} alt="" />
                            </div>
                        </div>
                    )}
                </Slider>
            </div>
        </section>
    )
}