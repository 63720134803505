import React, { useEffect, useState } from "react";
import './Footer.css'
import './Footer-Movil.css'
import { Menu } from "../Menu/Menu";
import { Socialmedia } from "../SocialMedia/Socialmedia";
import { useWindowSize } from "../hooks/windowSize";

export const Footer = () =>{

    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version

    //* read windows width size and   set hooks true or false
    const screenWidth = useWindowSize();    

    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 769) {
            setisVisible(true)
        } else if (screenWidth <= 768) {
            setisVisible(false)
        }
    }, [screenWidth]);

    const year = () => {
        return new Date().getFullYear()
    }

    const sendEmail = (e) => {
        e.preventDefault();
    }

    return (
        <footer className="footer">
            <section className="content">
                <div className="section1 content-separation">
                    <div className={`S1-logo-desc ${isVisible? '': 'disable'}`}>
                        <img src="/resources/Vectores/Wadua.svg" alt="SVG Logo blanco Wadua" />
                        <p>Convertimos el mundo empresarial en negocios rentables a partir de los datos sin dejar de lado la interacción de personas para personas. </p>
                    </div>
                    <div className={`S1-menu ${isVisible? '': 'disable'}`}>
                        <Menu isHeader={false} enable={true}/>
                    </div>
                    <div className={`iconPancho ${isVisible? 'disable': ''}`}>
                    <img src='/resources/Vectores/Button pancho.svg' alt="Svg Button Pancho" />
                    </div>
                    <div className={`Our-SM ${isVisible? 'disable': ''}`}>
                        <h4 className="socialTitle">Nuestras redes</h4>
                        <div className="SM-icons">
                        <Socialmedia/>
                        </div>
                    </div>
                </div>
                <div className="section2 content-separation">
                    <span>DEMO</span>
                    <h3> Solicita una <br/> demostración</h3>
                    <span> hr@wadua.com.co</span>
                    <form onSubmit={sendEmail} style={{'display':'none'}}>  {/*disable*/}
                        <input type="text" placeholder=" Correo electrónico..." />
                        <button><img src="/resources/Vectores/arrow.svg" alt="Svg arrow icon" /></button>
                    </form>
                    <div className={`Our-SM ${isVisible? '': 'disable'}`}>
                        <h4 className="socialTitle">Nuestras<br/>redes</h4>
                        <div className="SM-icons">
                        <Socialmedia/>
                        </div>
                    </div>
                    <div className={`S2-logo ${isVisible? 'disable': ''}`}>
                        <img src="/resources/Vectores/Wadua.svg" alt="SVG Logo blanco Wadua" />
                    </div>
                </div>
                <div className="section3 content-separation">
                    <div className="line"></div>
                    <img src="/resources/Vectores/Soluciones.svg" alt="SVG Slogan" />
                </div>
            </section>

            <div className="bottom">
                <span className={`bottom-text ${isVisible? '': 'disable'}`}>Términos de uso</span>
                <span className={`bottom-text ${isVisible? '': 'disable'}`}>Política de privacidad</span>
                <div className={`iconPancho ${isVisible? '': 'disable'}`}>
                    <img src='/resources/Vectores/Button pancho.svg' alt="Svg Button Pancho" />
                </div>
                <span className="bottom-text">&copy; {year()} Bamboo Analytics All Rights Reserved</span>
            </div>
            <img className="wadua" src="/resources/Vectores/Waduas.svg" alt="Svg Waduas" />
        </footer>        
    )
}