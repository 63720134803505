import React from "react";

import './Banner.css'

export const Banner = () =>{
    return(
        <section className="bannerP Section">
            <div className="corner">                
            </div>
            <img className="bannerP-img" src="/resources/Vectores/Soluciones.svg" alt="Soluciones Logo"/>
        </section>
    )
}