import React, {useEffect, useState}from "react";
import { Button } from "../../Button/Button";
import './AboutusCDesc.css';
import './AboutusCDesc-Movil.css'
import { useWindowSize } from "../../hooks/windowSize";

export const AboutusCDesc = ({contentS1, isEng}) =>{
    // console.log(contentS1);
    /** variables for use  Variable = [title, description]*/

    const Mision = [ contentS1.mision.title , contentS1.mision.desc]
    const Vision = [contentS1.vision.title, contentS1.vision.desc]
    const We = [contentS1.we.title, contentS1.we.desc]

    const [buttonText, setButtonText] = useState(We[1]);
    const [buttonTitle, setButtonTitle] = useState({title1:Vision[0],title2:Mision[0]});
    const [title, setTitle] = useState(We[0]);


    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version
    const [enableModal, setEnableModal] = useState(false);  

    //* read windows width size and   set hooks true or false
    const screenWidth = useWindowSize();    

    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 769) {
            setisVisible(true)
        } else if (screenWidth <= 768) {
            setisVisible(false)
        }
    }, [screenWidth]);

    function setVisionDesc(e, id) {   
        if( id === 1){
            let updateValue = {}
            if(buttonTitle.title1 === "Visión" || buttonTitle.title1 === "Vision" ){
                updateValue =  {title1:We[0]}
                if(buttonTitle.title2 === "¿Quiénes somos?" || buttonTitle.title2 === "About us" )
                {updateValue = {title1:Mision[0]}} 
                setButtonText(Vision[1])
                setTitle(Vision[0])
            } 
            else if(buttonTitle.title1 === "¿Quiénes somos?" || buttonTitle.title1 === "About us"){
                updateValue =  {title1:Vision[0]}
                if(buttonTitle.title2 === "Visión" || buttonTitle.title2 === "Vision" )
                {updateValue = {title1:Mision[0]}} 
                setButtonText(We[1])
                setTitle(We[0])
            } 
            else{
                updateValue =  {title1:Vision[0]}
                if(buttonTitle.title2 === "Visión" || buttonTitle.title2 === "Vision")
                {updateValue = {title1:We[0]}}
                setButtonText(Mision[1])
                setTitle(Mision[0])
            }
            
            setButtonTitle(title =>({
                ...title,
                ...updateValue
            }))
        }
        if( id === 2 ){
            let updateValue = {}
            if(buttonTitle.title2 === "Misión" ){
                updateValue =  {title2:We[0]}
                if(buttonTitle.title1 === "¿Quiénes somos?")
                {updateValue = {title2:Vision[0]}} 
                setButtonText(Mision[1])
                setTitle(Mision[0])
            } 
            else if(buttonTitle.title2 === "¿Quiénes somos?" ){
                updateValue =  {title2:Mision[0]}
                if(buttonTitle.title1 === "Misión")
                {updateValue = {title2:Vision[0]}} 
                setButtonText(We[1])
                setTitle(We[0])
            } 
            else{
                updateValue =  {title2:Mision[0]}
                if(buttonTitle.title1 === "Misión")
                {updateValue = {title2:We[0]}}
                setButtonText(Vision[1])
                setTitle(Vision[0])                
            }
            setButtonTitle(title =>({
                ...title,
                ...updateValue
            }))
        }
    }

    function showModule(e, id) {        
       if(id ===1){
        setButtonText(Vision[1])
        setTitle(Vision[0])
       }else{
        setButtonText(Mision[1])
        setTitle(Mision[0])
       } 
       setEnableModal(true)   
    }
    function hiddenModule() {
        setEnableModal(false)
        setButtonText(We[1])
        setTitle(We[0])
    }
    return(
        <section className="about-us-C-desc Section">
            
            <div className="abusc-img">
            </div>
            <div className="abusc-content">
                <h1 className="abusc-content-title general-title-h2">{title}</h1>
                <p className="abusc-content-desc">{buttonText}</p>
                <div className={`abusc-content-btns ${isVisible? '': 'disable'}`}>
                    <Button textBtn={buttonTitle.title1} event={(e)=>setVisionDesc(e,1)} bckColorClase='greenBtn' />
                    <Button textBtn={buttonTitle.title2} event={(e)=>setVisionDesc(e,2)} bckColorClase='greenBtn'/>
                </div>
            </div>
            <div className={`btns-mv  ${isVisible? 'disable': ''}`}>
                    <button className="btn-mv" onClick={(e)=>showModule(e,1)}>
                        <span>{Vision[0]}</span>                        
                        <span className="detail-mv">Ver más</span>
                    </button>
                    <button className="btn-mv" onClick={(e)=>showModule(e,2)}>
                        <span>{Mision[0]}</span>   
                        <span className="detail-mv">Ver más</span>
                    </button>
            </div>

            <div  className={`modalAUD ${enableModal? '': 'disable'}`} onClick={(e)=>hiddenModule()}>
                <div className="modalAUD-content">
                    <h2 className="modalAUD-title">{title}</h2>
                    <p className="modalAUD-content-p">{buttonText}</p>
                    <button className="btn-modal" onClick={(e)=>hiddenModule()}>
                        X
                    </button>
                </div>
            </div>

            
        </section>
    )
}