import React, { useEffect } from "react";

import { Section1 } from "../Components/KnowUsPage/Section1/Section1";
import { Section2 } from "../Components/KnowUsPage/Section2/Section2";
import { Section3 } from "../Components/KnowUsPage/Section3/Section3";
import { Section4 } from "../Components/KnowUsPage/Section4/Section4";
import { Section5 } from "../Components/KnowUsPage/Section5/Section5";

const info={
    aboutus : 'Somos una empresa de base tecnológica que busca potencializar y apoyar el uso correcto de los datos construyendo herramientas automatizadas que le permitan tomar las decisiones adecuadas en el tiempo correcto para aumentar la rentabilidad del área de su empresa que quiera impactar. '
}

export const KnowUs = ({content}) =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      
    return (
        <>
            <Section1 contents1={content.cultura}/>
            <Section2 contents2={content.values}/>
            <Section3 contents3={content.team_wadua}/>
            <Section4 contents4={content.installation}/>
            <Section5/>
        </>
    )
}